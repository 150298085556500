import React, { useState, useEffect } from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import * as queryString from 'query-string'
import { Helmet } from 'react-helmet'

/* COMPONENTS */
import Menu from '../../components/receitas/receitas-header'
import Layout from '../../components/Layout/index'
import SideBar from '../../components/receitas/SideBarReceitas/sideBarReceitas'
import SectionLancamentos from '../../components/SectionLancamentos/sectionLancamentos'
import Pagination from '../../components/busca/pagination/pagination'

/* STYLES */
import '../../pages/styles/receitas.scss'

const BuscaReceita = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [postPerPage] = useState(6)

  const [query, setQuery] = useState('')

  const breadC = {
    visible: true,
    color: 'dark',
    items: [
      { path: '/', name: 'Home' },
      { path: '/receitas/', name: 'Receitas' }
    ]
  }
  if (typeof window !== 'undefined') {
    useEffect(() => {
      const { q } = queryString.parse(window.location.search)
      setQuery(q)
    }, [window.location.search])
  }
  const receitas = data.receitas.edges.filter((rec) =>
    (rec.node.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(query.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().normalize('NFD'))))

  return (
    <Layout breadCrumb={breadC}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Receitas DIA - Receitas saborosas, rápidas e fáceis para você!</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="No DIA Supermercados, além dessa receita, você também encontra receitas de Sobremesas, Pães e Salgados, dentre outras. Confira!" />
        <meta name="author" content="DIA Supermercados" />
      </Helmet>
      <section id="receitas">
        <section id="filtragem-home">
          <div className="menu-receitas">
            <Menu />
          </div>
        </section>
        <section className='container'>
          <div className="row">
            <div className="col-12 col-lg-8">
              <div className="d-flex row">
                <div className="col-12">
                  <h1 className="text-red mb-3 text-uppercase">Resultado da busca</h1>
                  <div style={{ background: '#E4F5F6' }} className="p-3 rounded mb-3">
                    <h3 className="text-uppercase text-blue mb-0"><span className="font-weight-bold mr-1">{receitas.length}</span> Resultados para: <span className="font-weight-bold">{query}</span></h3>
                  </div>
                </div>
                {receitas.slice((postPerPage * currentPage), (postPerPage * (currentPage + 1))).map((rec, index) => (
                  <div className={`col-6 mb-4 ${index % 2 === 0 ? 'padding-right-custom-cat' : 'padding-left-custom-cat'}`} key={index}>
                    <Link to={`/receitas/${rec.node.categoriaSlug}/${rec.node.slug}/`} className="text-dark">
                      <div className="card cardReceita">
                        <div className='d-flex flex-column align-items-start justify-content-end w-100'>
                          <GatsbyImage
                            image={rec.node.image.localFile.childImageSharp.gatsbyImageData}
                            className='imgReceita'
                            alt="Imagem da Receita" />
                          <p className='position-absolute font-Yanone font-size-17 text-white bg-dia-blue rounded mx-4 px-2 py-0 mb-3 text-center'>{rec.node.categoria}</p>
                        </div>
                        <div className="card-body py-2 px-4">
                          <p className='font-size-15 text-left mb-0 limit-text-lg font-Yanone'><span>{rec.node.title}</span></p>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
              <div className='d-flex justify-content-center w-100 mb-4'>
                <Pagination
                  postPerPage={postPerPage}
                  totalPosts={receitas}
                  handlers={{
                    currentPage,
                    setCurrentPage
                  }}
                />
              </div>
            </div>
            <div className='col-12 col-lg-4'>
              <SideBar />
            </div>
          </div>
        </section>
        <section className='container-fluid bg-dia-light-gray'>
          <section className="container py-5 p-0">
            <SectionLancamentos />
          </section>
        </section>
      </section>
    </Layout >
  );
}

export default BuscaReceita

export const query = graphql`{
  receitas: allContentfulReceitas(sort: {fields: [createdAt], order: DESC}) {
    edges {
      node {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        id
        urLyoutube
        slug
        tagsReceitas
        categoria
        categoriaSlug
        title
      }
    }
  }
}
`
